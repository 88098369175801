/**
 * Occupant App
 * Author: Manikandabose Nagarajan
 * Created On: Feb 03, 2022
 */
// Imports
import { createRouter } from 'router5';
import { mobxPlugin } from 'mobx-router5';
import loggerPlugin from 'router5-plugin-logger';
import browserPlugin from 'router5-plugin-browser';
import routes from './routes';
import stores  from './stores';
// See router5 for more details about this file
// Config router function
export default function configureRouter() {
    return createRouter(routes, { allowNotFound: true })
        .usePlugin(mobxPlugin(stores.routerStore))
        .usePlugin(browserPlugin({ useHash: false }))
        // Important step so we can expose store
        // Used for logging out state
        .usePlugin(loggerPlugin);
}
