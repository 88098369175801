/**
 * Occupant App
 * Author: Manikandabose Nagarajan
 * Created On: Feb 03, 2022
 */
// Imports
import { observable, action } from 'mobx';

// Global
declare global {
    // Environment Variables
    interface Window {
      ENV: {
        LOCALYTICS_SESSION_TIMEOUT: number;
        LAUNCHDARKLYCLIENTID:string;
        DEPENDENT_SERVICE_BASE_URL: string;
        RAMDOMIZATION_API_BASE_URL: string;
      };
    }
  }

// Global store
export default class GlobalStore {
  // Sidebar collapsed
  @observable
  public sideBarCollapsed: boolean = false;
  // Localyitics App Key
  @observable
  public LOCALYTICS_APP_KEY: string = '';
  // Toggle sidebar action
  @action
  toggleSideBar(state: boolean) {
    this.sideBarCollapsed = state;
  }
  // Localytics App Key action
  @action
  setLocalyticsAppKey(state: string)
  {
    this.LOCALYTICS_APP_KEY = state;
  }
  // Fetch localytics app key
  @action
  fetchLocalyticsAppKey() {
    //make fetch call to get the Localytics APP Key
    //response = api_call
    //this.setLocalyticsAppKey(response);
    this.setLocalyticsAppKey('App-key-Value-from-API');
  }
}