/**
 * Occupant App
 * Author: Manikandabose Nagarajan
 * Created On: Feb 03, 2022
 */
// Imports
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import '@scuf/common/honeywell/theme.css';
import store from './stores';
import { Provider } from 'mobx-react';
import createRouter from './router';
import './index.scss';
import AppLoader from './components/Loader/AppLoader';
const LazyApp = React.lazy(() => import('./App'));


// Here we are importing our stores file and spreading it across this Provider. All stores added to this will be accessible via child injects
// Wrapper component
const wrappedApp = (
  // Provider attached with store
  <Provider {...store}>
     <Suspense fallback={<AppLoader/>}>
      <LazyApp />
     </Suspense>
  </Provider>
);

// Here the router is bootstrapped
const router = createRouter();

// Router start
router.start(() => {
  ReactDOM.render(wrappedApp, document.getElementById('root') as HTMLElement);
});