/**
 * Occupant App
 * Author: Manikandabose Nagarajan
 * Created On: Feb 03, 2022
 */
// Imports
import { RouterStore } from 'mobx-router5';
import { Route, Params } from 'router5/create-router';

// We extend the Route class from router5 to be able to read the params on the route.
// This gives us an observable in the route store we can read from.
// Then simply extend the RouterStore to use it.
// Interface Device Route
export interface IDeviceRoute extends Route {
    params: Params;
}
// Interface Global Router Store
// Extends router store
export class GlobalRouterStore extends RouterStore {
    // Route
    public route!: IDeviceRoute;
}
// Router store
const routerStore = new GlobalRouterStore();
// Default export
export default routerStore;