/**
 * Occupant App
 * Author: Manikandabose Nagarajan
 * Created On: Feb 03, 2022
 */
// Imports
import ITodoModel from '../../models/ITodoModel';

// Mock
export const MockTodos: Array<ITodoModel> = [
    {
        dateCreated: new Date(),
        id: '1',
        title: 'Leave for flight',
        description: 'Go to the airport',
        completed: false },
    {
        id: '2',
        dateCreated: new Date(),
        title: 'Order a taxi',
        description: 'Call the service for ride to airport',
        completed: true },
    {
        id: '3',
        dateCreated: new Date(),
        title: 'Pack clothes',
        description: 'Prepare enough outfits for one week visit.',
        completed: false }];