/**
 * Occupant App
 * Author: Manikandabose Nagarajan
 * Created On: Feb 03, 2022
 */
// Imports
import { constants } from 'router5';
import React from 'react';
const lazyContainer = React.lazy(() => import('./pages/Feedback/FeedbackContainer'));
const lazyNotFoundPage = React.lazy(() => import('./pages/NotFound/NotFound'));
/*
    - Routes.ts is the master router config file. Each item defines a route by its name and the parameters it accepts.
    - The component bound to the route will be loaded into the base route view component.
*/
export default [
    {
        name: 'feedback',
        path: '/',
        component: lazyContainer},
    // This entry captures any unknown routes and redirects them to a component. This is our 404 page.
    {
        name: constants.UNKNOWN_ROUTE,
        path: '/not-found',
        component: lazyNotFoundPage }];