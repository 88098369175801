
/**
 * Occupant App
 * Author: Divya Tiwari
 * Created On: June 20, 2022
 * Updated on: Sept 09, 2022
 */

// Imports
import React from 'react';
import './AppLoader.scss';
import doubleRing from  './Double-Ring.gif';
/*
 *SineAppLoader component
 *
 */
// Component
const appLoader = () => (
  // div for animated image layer
  <div
    className='layer'
  >
<img src={doubleRing} width={51} height={50}/>
  {/* div for animated image layer */}
  </div>
);
// default export
export default appLoader;
